import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>
      <h2>ANDROID MOBILE DEVELOPER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Fresh graduate IT/CS or other related course or 1 year relevant working experience</li>
          <li>Basic proficiency in Java, Android SDK, different versions of Android, and how to deal with different screen sizes</li>
          <li>Basic knowledge of Objective-C / Swift / Cocoa Touch and iOS frameworks such as Core Data, Core Animation, etc.</li>
          <li>Able to work well under pressure</li>
          <li>Positive can-do attitude, excellent communication & interpersonal skills</li>
          <li>Passion in learning new innovatives technologies</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
